import React from 'react';
import { AuditSection } from 'views/technologies/audit-section';
import { ExpertsSection } from 'views/technologies/experts-section';
import { TechnologiesMainSection } from 'views/technologies/technologies-main-section';
import { QuoteBannerSection } from 'modules/quote-banner-section';
import { RangeOfTechnologiesSection } from 'modules/range-of-technologies-section';
import { RequestSection } from 'modules/request-section';
import { ToolsSection } from 'modules/tools-section';
import { TrustedSection } from 'modules/trusted-section';

export const TechnologiesView = () => {
    return (
        <>
            <TechnologiesMainSection />
            <TrustedSection />
            <RangeOfTechnologiesSection />
            <QuoteBannerSection
                quote="quotes.mobileRealityCompany"
                author="quotes.justynaSznajder"
                position="quotes.productManager"
            />
            <ExpertsSection />
            <AuditSection />
            <ToolsSection />
            <RequestSection />
        </>
    );
};
