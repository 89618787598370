import React from 'react';
import LemlistLogo from 'svgs/technologies/tools-we-use/lemlist.svg';
import MakeLogo from 'svgs/technologies/tools-we-use/make.svg';
import PhantomBusterLogo from 'svgs/technologies/tools-we-use/phantom-buster.svg';
import SeRankingLogo from 'svgs/technologies/tools-we-use/se-ranking.svg';
import WebflowLogo from 'svgs/technologies/tools-we-use/webflow.svg';
import WebstormLogo from 'svgs/technologies/tools-we-use/webstorm.svg';

export const TOOLS_WE_USE = [
    {
        logo: <LemlistLogo />,
        href: 'https://get.lemlist.com/olcenvl8eusu',
        name: 'technologies-page.tools.lemlist',
        target: '_blank',
    },
    {
        logo: <SeRankingLogo />,
        href: 'https://seranking.com/?ga=1497543&source=link',
        name: 'technologies-page.tools.seRanking',
        target: '_blank',
    },
    {
        logo: <MakeLogo />,
        href: 'https://www.make.com/en/register?pc=mobilereality',
        name: 'technologies-page.tools.make',
        target: '_blank',
    },
    {
        logo: <PhantomBusterLogo />,
        href: 'https://phantombuster.com/?deal=matt51',
        name: 'technologies-page.tools.phantomBuster',
        target: '_blank',
    },
    {
        logo: <WebflowLogo />,
        href: 'https://themobilereality.com/services/webflow-agency',
        name: 'technologies-page.tools.webflow',
    },
    {
        logo: <WebstormLogo />,
        href: 'https://themobilereality.com/blog/javascript/best-javascript-ide',
        name: 'technologies-page.tools.webstorm',
    },
];
