import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import styled from 'styled-components';
import { Container } from 'components/container';
import { HeaderSecond } from 'components/header-second';
import { CONSTANTS } from 'constants/styles/constants';
import { useTranslatedTitle } from 'hooks/use-translated-title';
import { TOOLS_WE_USE } from 'modules/tools-section/constants';

const SContainer = styled(Container)`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const SHeaderSecond = styled(HeaderSecond)`
    margin-bottom: 3.75rem;
`;

const SWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, auto);
    gap: 1.25rem;
    width: 100%;
    justify-content: center;

    grid-template-areas:
        'tool1 tool2 tool3 tool4'
        '. tool5 tool6 .';

    ${CONSTANTS.MEDIA.max1024`
        grid-template-columns: repeat(2, 1fr);
        grid-template-areas:
            "tool1 tool2"
            "tool3 tool4"
            "tool5 tool6";
    `}

    ${CONSTANTS.MEDIA.max768`
        grid-template-columns: repeat(1, 1fr);
        grid-template-areas:
            "tool1"
            "tool2"
            "tool3"
            "tool4"
            "tool5"
            "tool6";
    `}
`;

const SLogoWrapper = styled.a`
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--gray-color-90);
    min-height: 10.5rem;
    padding: 1rem;

    &:nth-child(1) {
        grid-area: tool1;
    }
    &:nth-child(2) {
        grid-area: tool2;
    }
    &:nth-child(3) {
        grid-area: tool3;
    }
    &:nth-child(4) {
        grid-area: tool4;
    }
    &:nth-child(5) {
        grid-area: tool5;
    }
    &:nth-child(6) {
        grid-area: tool6;
    }
`;

export const ToolsSection = () => {
    const { kebabCaseId, handleCopyUrl } = useTranslatedTitle(
        'technologies-page.toolsThatWeUse',
    );
    const { formatMessage } = useIntl();
    const toolsToRender = TOOLS_WE_USE.map(({ logo, href, name, target }) => (
        <SLogoWrapper
            key={name}
            href={href}
            target={target}
            rel="noreferrer"
            aria-label={formatMessage({
                id: name,
            })}
        >
            {logo}
        </SLogoWrapper>
    ));

    return (
        <SContainer>
            <SHeaderSecond id={kebabCaseId} handleCopyUrl={handleCopyUrl}>
                <FormattedMessage id="technologies-page.toolsThatWeUse" />
            </SHeaderSecond>
            <SWrapper>{toolsToRender}</SWrapper>
        </SContainer>
    );
};
